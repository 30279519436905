<template>
  <div>
    <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowstoskip + fetchrows - (fetchrows - 1) }}
            -
            {{
              rowstoskip + fetchrows > record ? record : rowstoskip + fetchrows
            }}
          </b>
          <b v-else> 0 </b>
          Elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div v-if="!loading && record != 0 && status == 200">
      <Datatable
        :table-header="tableHeader"
        :table-data="vivaioList"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchrows"
        :sortLabel="sortcolumn"
        :order="sortorder"
        @sort="$emit('onSort', $event)"
        @items-per-page-change="$emit('onChangeFetchRow', $event)"
        @current-change="$emit('onPageChange', $event)"
      >
        <template v-slot:cell-nominativo_completo="{ row: vivaioList }"
          ><div class="tab-long">
            {{ vivaioList.nominativo_completo }}
          </div></template
        >
        <template v-slot:cell-denominazione="{ row: vivaioList }"
          ><div class="tab-long">
            {{ vivaioList.denominazione }} ({{ vivaioList.cod_affiliazione }})
          </div>
        </template>
        <template v-slot:cell-stagioni_string="{ row: vivaioList }"
          >{{ vivaioList.stagioni_string }}
        </template>
      </Datatable>
    </div>
    <div class="mx-auto my-auto text-center" v-else-if="loading">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="vivaioList.length === 0 && !loading && status == 200"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="!loading && status == 400"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!--<i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i>-->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import isEnabled from "@/composables/isEnabled.js";
import AddVivaio from "./AddVivaio.vue"; //da lasciare per stili se no crisi
import { ref } from "vue";

export default {
  name: "table-vivaio-old",
  components: { Datatable },
  emits: ["onSort", "onChangeFetchRow", "onPageChange", "resetFilters"],
  props: {
    vivaioList: {
      type: Array,
      required: true,
    },
    record: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    rowstoskip: {
      type: Number,
      required: true,
    },
    fetchrows: {
      type: Number,
      required: true,
    },
    sortcolumn: {
      type: String,
      required: true,
    },
    sortorder: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const tableHeader = ref([
      {
        name: "Tesserato",
        key: "nominativo_completo",
      },
      {
        name: "Società",
        key: "denominazione",
      },
      {
        name: "Stagioni",
        key: "stagioni_string",
        sortable: false,
      },
    ]);

    return {
      tableHeader,
      isEnabled,
    };
  },
};
</script>
