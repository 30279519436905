<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4">
          <div class="row">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Tesserato"
                  name="nome_tesserato"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'tesserato';
                    getPersone($event.target.value);
                  "
                  v-model="nome_tesserato"
                  autocomplete="off"
                />
                <input
                  name="id_tesserato"
                  type="text"
                  style="display: none"
                  class="form-control"
                  v-model="id_tesserato"
                /><svg
                  @click="
                    nome_tesserato = '';
                    id_tesserato = null;
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                v-if="inputFocus === 'tesserato'"
                :list="tesserati"
                @hideList="resetTesserati"
                @selectedTesserato="
                  resetTesserati();
                  nome_tesserato = $event.name;
                  id_tesserato = $event.value;
                "
              ></ListTesserati>
            </div>
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="nome_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  v-model="nome_societa"
                  autocomplete="off"
                />
                <input
                  name="id_societa"
                  type="text"
                  style="display: none"
                  class="form-control"
                  v-model="id_societa"
                /><svg
                  @click="
                    nome_societa = '';
                    id_societa = null;
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="resetSocieta"
                @selectedSocieta="
                  resetSocieta();
                  nome_societa = $event.name;
                  id_societa = $event.value;
                "
              ></ListSocieta>
            </div>
          </div>
          <div class="text-center mt-5">
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('setIds', {
                  id_Societa: id_societa == 0 ? null : id_societa,
                  id_Persona: id_tesserato == 0 ? null : id_tesserato,
                })
              "
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i
            ></span>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                spliceSocieta();
                spliceTesserati();
                $emit('setIds', {
                  id_Societa: null,
                  id_Persona: null,
                });
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";

export default defineComponent({
  name: "filtri-vivaio-old",
  emits: ["setIds"],
  props: {
    resetted: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
  },
  components: { ListTesserati, ListSocieta },
  setup(props) {
    const store = useStore();
    const nome_tesserato = ref("");
    const id_tesserato = ref(null);

    const currentPageNew = ref(props.currentPage);

    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_tesserato.value = "";
      id_tesserato.value = 0;
      resetTesserati();
    };

    const nome_societa = ref("");
    const id_societa = ref(null);

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    const spliceSocieta = () => {
      nome_societa.value = "";
      id_societa.value = 0;
      resetSocieta();
    };

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          nome_societa.value = "";
          id_societa.value = null;
          nome_tesserato.value = "";
          id_tesserato.value = null;
        }
      }
    );

    const inputFocus = ref(null);

    return {
      inputFocus,
      currentPageNew,
      nome_tesserato,
      id_tesserato,
      nome_societa,
      getPersone,
      id_societa,
      resetTesserati,
      getSocieta,
      resetSocieta,
      spliceTesserati,
      spliceSocieta,
      tesserati: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
    };
  },
});
</script>

<style></style>
